<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        document.isTemplate
          ? t("headings.edit_template")
          : t("headings.edit_document")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="state.name.value" class="card p-4">
      <div class="mb-3 grid grid-cols-1 gap-3 md:grid-cols-3">
        <div class="w-full">
          <label>{{ t("labels.document_name") }}</label>
          <form-input
            class="w-full"
            id="document-name-input"
            :invalid="state.name.error ? true : false"
            type="text"
            v-model="state.name.value"
          />
        </div>
        <div class="w-full">
          <label>{{ t("labels.document_type") }}</label>
          <form-select
            id="document-type-select"
            :invalid="state.classificationId.error ? true : false"
            :optionLabel="['label']"
            :options="classifications"
            translatableLabel="classifications"
            v-model="state.classificationId.value"
          />
        </div>
        <div class="w-full">
          <label>{{ t("labels.estate_name") }}</label>
          <form-select
            id="document-estate-select"
            :invalid="state.estateId.error ? true : false"
            :optionLabel="['name']"
            :options="estates"
            v-model="state.estateId.value"
          />
        </div>
      </div>
      <editor v-model="state.content.value" />
      <hr class="my-3 border-gray-300" />
      <div class="flex justify-end gap-3">
        <form-button
          :command="() => (document.isTemplate ? saveTemplate() : save())"
          :disabled="waiting"
          id="submit-button"
          label="save"
          :textVariant="company.primaryText"
          type="submit"
          :variant="company.primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import Editor from "../components/editor/Editor.vue";
import FormButton from "../components/form/FormButton.vue";
import FormInput from "../components/form/FormInput.vue";
import FormSelect from "../components/form/FormSelect.vue";
import validate from "../utils/validators";
export default {
  components: { Breadcrumb, Editor, FormButton, FormInput, FormSelect },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    const classifications = computed(
      () => store.state.document.classifications
    );
    const company = computed(() => store.state.company.company);
    const document = computed(() => store.getters["document/getDocument"]);
    const estates = computed(() => store.state.estate.estates);

    const waiting = computed(() => {
      if (store.state.document.waiting) return true;
      if (store.state.estate.waiting) return true;
      return false;
    });

    const state = reactive({
      classificationId: {
        error: null,
        rules: ["isRequired"],
        value: null,
      },
      content: {
        error: null,
        rules: [],
        value: "",
      },
      estateId: {
        error: null,
        rules: ["isRequired"],
        value: null,
      },
      name: {
        error: null,
        rules: ["isRequired"],
        value: "",
      },
    });

    const setState = () => {
      state.classificationId.value = document.value.classificationId;
      state.content.value = JSON.parse(document.value.content);
      state.estateId.value = document.value.estateId;
      state.name.value = document.value.name;
    };

    const isFormValid = async (isValid = true) => {
      for (const [_, value] of Object.entries(state)) {
        value.error = await validate(value.rules, value.value);
        if (value.error) isValid = false;
      }
      return isValid;
    };

    const save = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {
        classificationId: state.classificationId.value,
        content: JSON.stringify(state.content.value),
        estateId: state.estateId.value,
        id: document.value.id,
        name: state.name.value,
      };
      store.dispatch("document/updateDocument", data);
    };

    const saveTemplate = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {
        classificationId: state.classificationId.value,
        content: JSON.stringify(state.content.value),
        estateId: state.estateId.value,
        id: document.value.id,
        name: state.name.value,
      };
      store.dispatch("document/updateTemplate", data);
    };

    onMounted(() => {
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
      if (!classifications.value.length) {
        store.dispatch("document/getClassifications");
      }
      if (!document.value.id) {
        store.dispatch("document/getDocument", route.params.documentId);
      } else {
        setState();
      }
    });

    watch(
      () => document.value,
      (document) => {
        if (document.id) {
          setState();
        }
      }
    );

    return {
      classifications,
      company,
      document,
      estates,
      route,
      save,
      saveTemplate,
      state,
      t,
      waiting,
    };
  },
};
</script>
